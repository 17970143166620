var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    [
      _c("div", { staticClass: "tabbar-main" }, [
        _c(
          "div",
          {
            class: { choose: _vm.newPath === "/index" },
            on: {
              click: function ($event) {
                return _vm.goUrl("/index")
              },
            },
          },
          [_vm._v("知政")]
        ),
        _c(
          "div",
          {
            class: { choose: _vm.newPath === "/answerData" },
            on: {
              click: function ($event) {
                return _vm.goUrl("/answerData")
              },
            },
          },
          [_vm._v("问数")]
        ),
        _c("div", {
          staticClass: "choose add iconfont icon-increase",
          on: {
            click: function ($event) {
              return _vm.goUrl()
            },
          },
        }),
        _c(
          "div",
          {
            on: {
              click: function ($event) {
                return _vm.goUrl()
              },
            },
          },
          [_vm._v("知识库")]
        ),
        _c(
          "div",
          {
            on: {
              click: function ($event) {
                return _vm.goUrl()
              },
            },
          },
          [_vm._v("历史")]
        ),
      ]),
      _c("Loading", { ref: "Loading" }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }