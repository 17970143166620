<template>
  <section>
    <div class="tabbar-main">
      <div :class="{ choose: newPath === '/index' }" @click="goUrl('/index')">知政</div>
      <div :class="{ choose: newPath === '/answerData' }" @click="goUrl('/answerData')">问数</div>
      <div @click="goUrl()" class="choose add iconfont icon-increase"></div>
      <div @click="goUrl()">知识库</div>
      <div @click="goUrl()">历史</div>
    </div>
    <Loading ref="Loading" />
  </section>
</template>
<script>
export default {
  name: 'Tabbar',
  components: {
    Loading: () => import('@/components/Loading/index.vue'),
  },
  props: {},
  data() {
    return {
      newPath: '/index',
    }
  },
  computed: {},
  watch: {},
  beforeCreate() {},
  created() {},
  beforeMount() {},
  mounted() {},
  beforeUpdate() {},
  updated() {},
  methods: {
    goUrl(path) {
      console.log(this.$route)
      console.log('goUrl')
      if (path) {
        if (this.$route.path !== path) {
          this.newPath = path
          this.$router.replace({ path })
        }
      } else {
        this.$refs['Loading'].showOverlay()
      }
      //   this.show = !this.show
      // this.$refs['Loading'].showOverlay()
    },
  },
  beforeDestroy() {},
  destroyed() {},
}
</script>
<style scoped lang="less" type="text/less">
.tabbar-main {
  background-color: white;
  height: 40px;
  position: fixed;
  bottom: -3px;
  left: 0;
  right: 0;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  text-align: center;
  font-weight: bolder;
  //   line-height: 40px;
  // color: #646466;
  color: #9090ad;
  .choose {
    color: #3b3d3d;
    border-color: #3b3d3d !important;
  }
  .add {
    border: 2px solid #9090ad;
    border-radius: 20px;
    padding: 2px 10px;
  }
}
</style>
